(function ($) {

    var MEREJEDAPP = window.MEREJEDAPP || {},
        UTIL = window.UTIL || {};

    MEREJEDAPP = {

        common: {
            init: function () {

                $('a[href="#"]').on('click', function (e) {
                    e.preventDefault();
                });


                // ------ Search Overlay ------ //
                $('.search-overlay-btn').on('click', function () {
                    console.log('btn clicked');
                    $('.search-overlay').fadeIn(250);
                    $('.search-field').focus();
                });
                $('.search-overlay-close .close').on('click', function () {
                    $('.search-overlay').fadeOut(250);
                });


                // ------ Smooth scrolling anchor links ------ //
                // http://css-tricks.com/snippets/jquery/smooth-scrolling/
                $('a[href*=#]:not([href=#])').not('a[data-toggle="collapse"]').not('a[href="#headerCarousel"]').click(function () {
                    if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                        if (target.length) {
                            $('html,body').animate({
                                scrollTop: target.offset().top
                            }, 1000);
                            return false;
                        }
                    }
                });

                $('.panel-collapse').on('shown.bs.collapse', function () {
                    var id = $(this).attr('id');
                    $('html,body').animate({
                        scrollTop: $('#' + id).offset().top + -100
                    }, 1000);
                });

                // ------ Scholarship Recipients Carousel ------ //
                var slider1 = $('#scholarshipRecipientsCarousel').lightSlider({
                    item: 4,
                    loop: true,
                    cssEasing: 'ease-in-out',
                    speed: 250,
                    auto: true,
                    controls: false,
                    pause: 7000,
                    adaptiveHeight: true,
                    onSliderLoad: function (el) {
                        el.addClass('loaded');
                    },
                    responsive : [
                    {
                        breakpoint: 992,
                        settings: {
                            item: 3,
                            slideMargin: 6
                          }
                    },
                    {
                        breakpoint: 676,
                        settings: {
                            item: 2
                          }
                    }
                ]
                });

                $('.lSPrev').on('click', function () {
                    slider1.goToPrevSlide();
                });
                $('.lSNext').on('click', function () {
                    slider1.goToNextSlide();
                });

                // ------ Current Recipients Carousel ------ //
                var slider2 = $('#currentRecipientsCarousel').lightSlider({
                    item: 4,
                    loop: true,
                    cssEasing: 'ease-in-out',
                    speed: 250,
                    auto: true,
                    controls: false,
                    pause: 7000,
                    adaptiveHeight: true,
                    onSliderLoad: function (el) {
                        el.addClass('loaded');
                    },
                    responsive : [
                    {
                        breakpoint: 992,
                        settings: {
                            item: 3,
                            slideMargin: 6
                          }
                    },
                    {
                        breakpoint: 676,
                        settings: {
                            item: 2
                          }
                    }
                ]
                });

                $('.lSPrev2').on('click', function () {
                    slider2.goToPrevSlide();
                });
                $('.lSNext2').on('click', function () {
                    slider2.goToNextSlide();
                });

                $(window).scroll(function () {
                    if ($(this).scrollTop() > 150){  
                      $('.navbar-wrapper').addClass('sticky-xl-top');
                    }
                    else {
                      $('.navbar-wrapper').removeClass('sticky-xl-top');
                    }
                    if ($(this).scrollTop() > 151){  
                        $('.navbar-wrapper').addClass('h-header-sticky-in');
                      }
                      else {
                        $('.navbar-wrapper').removeClass('h-header-sticky-in');
                      }
                });

                
                const $cards = $('.carousel-item .card');
                const $indicators = $('.carousel-indicators button');

                let activeIndex = 0;

                $cards.each(function (index, element) {
                  element.addEventListener('click', () => {4
                    if (element.classList.contains('active')) return;
                    $cards.removeClass('expand').removeClass('unset').removeClass('active').addClass('small');
                    $indicators.removeClass('active');

                    element.classList.remove('small');
                    $(element).addClass('expand active');

                    $($indicators[index]).addClass('active');

                    $cards.each(function (index, element) {
                        $(element).find('video')[0].pause();
                    });

                    if (index == 0) $('.carousel-control-prev')[0].disabled = true;
                    else $('.carousel-control-prev')[0].disabled = false;

                    if (index == $cards.length - 1) $('.carousel-control-next')[0].disabled = true;
                    else $('.carousel-control-next')[0].disabled = false;

                    activeIndex = index;

                  });
                });
                
                $indicators.each(function (index, element) {
                    element.addEventListener('click', () => {
                      if (element.classList.contains('active')) return;
                      $cards.removeClass('expand').removeClass('unset').removeClass('active').addClass('small');
                      $indicators.removeClass('active');

                      $cards[index].classList.remove('small');
                      $($cards[index]).addClass('expand active');

                      $(element).addClass('active');

                      $cards.each(function (index, element) {
                          $(element).find('video')[0].pause();
                      });

                      if (index == 0) $('.carousel-control-prev')[0].disabled = true;
                      else $('.carousel-control-prev')[0].disabled = false;

                      if (index == $cards.length - 1) $('.carousel-control-next')[0].disabled = true;
                      else $('.carousel-control-next')[0].disabled = false;

                      activeIndex = index;
                    });
                });

                function onCarouselNextPrev() {
                    $cards.removeClass('expand').removeClass('unset').removeClass('active').addClass('small');
                    $indicators.removeClass('active');

                    $cards[activeIndex].classList.remove('small');
                    $($cards[activeIndex]).addClass('expand active');

                    $($indicators[activeIndex]).addClass('active');

                    $cards.each(function (index, element) {
                        $(element).find('video')[0].pause();
                    });
                }

                $('.carousel-control-prev')[0].addEventListener('click', function () {
                    if (activeIndex == 1) this.disabled = true;
                    $('.carousel-control-next')[0].disabled = false;
                    activeIndex--;
                    onCarouselNextPrev();
                });

                $('.carousel-control-next')[0].addEventListener('click', function () {
                    if (activeIndex == $cards.length - 2) this.disabled = true;
                    $('.carousel-control-prev')[0].disabled = false;
                    activeIndex++;
                    onCarouselNextPrev();
                });


                const thumbnailGalleryModal = document.getElementById('thumbnailGalleryModal');
                if (thumbnailGalleryModal) {
                    thumbnailGalleryModal.addEventListener('show.bs.modal', event => {
                        // Button that triggered the modal
                        const button = event.relatedTarget;
                        // Extract info from data-bs-* attributes
                        const imageUrl = button.getAttribute('data-bs-url');
                        // If necessary, you could initiate an Ajax request here
                        // and then do the updating in a callback.

                        // Update the modal's content.
                        const modalImageContainer = thumbnailGalleryModal.querySelector('.modal-image-container');

                        var oImg = document.createElement('img');
                        oImg.setAttribute('src', imageUrl);
                        oImg.classList.add('w-100');
                        modalImageContainer.replaceChildren(oImg);
                    });
                }

            },
            pagesSidebarActive: function () {
                $('.sidebar').stick_in_parent();
            }
        },

        home: {
            init: function () {
                // ------ Fade in header background image ------ //
                $('.carousel-header-image:first-child').waitForImages(function() {
                    $(this).addClass('image-loaded');
                });
            }
        },

        donate: {
            init: function () {

                $('#donate-amt').focus();

                // ------ Donate form validation ------ //
                $('#donate-amt, #donate-amt-recurring').on('blur', function () {
                    var $this = $(this),
                        val = $this.val(),
                        min = $this.data('min'),
                        max = $this.data('max'),
                        isError = false,
                        reg = /^\d+(\.\d{1,2})?$/;

                    if (!isNaN(val)) {
                        var num = parseFloat(val);
                        if (num < min || num > max || !reg.test(val)) {
                            isError = true;
                        }
                    } else {
                        isError = true;
                    }

                    if (isError) {
                        $('[data-toggle-class="error"]').addClass('error');
                        $('.donate-paypal-btn').prop('type', 'button');
                    } else {
                        $('[data-toggle-class="error"]').removeClass('error');
                        $('.donate-paypal-btn').prop('type', 'submit');
                    }
                });


                // ------ Standard payment / Recurring payment switching ------ //
                $('#donate-amt').on('keyup', function () {
                    $('#donate-amt-recurring').val($(this).val());
                });
                $('#donate-amt-recurring').on('keyup', function () {
                    $('#donate-amt').val($(this).val());
                });

                $('#recurring').prop('checked', false);

                $('#recurring').on('change', function () {
                    if ($(this)[0].checked) {
                        $('.donate-standard').hide();
                        $('.donate-recurring').removeClass('hidden');
                        $('.donate-recurring').show();
                    } else {
                        $('.donate-recurring').hide();
                        $('.donate-standard').show();
                    }
                });


                // ------ Donation category ------ //
                $('#donate-cat').on('change', function () {
                    var val = $(this).val();
                    $('.item_name').each(function () {
                        $(this).val('');
                        $(this).val($(this).data('value') + val);
                    });
                });

            }
        },

        contact: {
            init: function () {

                // ------ Process contact ------ //
                $('#contactSubmit').on('click', function () {

                    var contactName = $('#contactName').val(),
                        contactEmail = $('#contactEmail').val(),
                        contactTel = $('#contactTel').val(),
                        contactMessage = $('#contactMessage').val(),
                        dataString = 'contactName=' + contactName + '&contactEmail=' + contactEmail + '&contactTel=' + contactTel + '&contactMessage=' + contactMessage;

                    $.ajax({
                        type: 'POST',
                        url: merejed_object.theme_url + '/inc/process-contact.php',
                        data: dataString,

                        success: function () {

                            $('#inquiry-form-container').hide();
                            $('#inquiry-form-thank-you').fadeIn(500);

                        }

                    });

                    return false;

                });

            }
        }
    };

    UTIL = {
        fire: function (controller, commonModule) {
            var ns = MEREJEDAPP;

            if (controller !== '' && ns[controller]) {
                if (commonModule === undefined) {
                    ns[controller].init();
                } else if (typeof ns[controller][commonModule] === 'function') {
                    ns[controller][commonModule]();
                }
            }
        },
        loadEvents: function () {
            var body = document.body,
                controller = body.getAttribute('data-controller'),
                modules = body.getAttribute('data-common-modules');

            UTIL.fire('common');
            UTIL.fire(controller);

            if (modules !== null) {
                $.each(modules.split(/\s+/), function (i, moduleName){
                      UTIL.fire('common', moduleName);
                });
            }
        }
    };

    $(document).ready(UTIL.loadEvents);

})(jQuery);